import React from "react";
import { Route, Routes } from "react-router-dom";

const HomeScreen = React.lazy(() => import("./screens/HomeScreen"));
const LoginScreen = React.lazy(() => import("./screens/LoginScreen"));
const CreatorsScreen = React.lazy(() => import("./screens/CreatorsScreen"));
const UsersScreen = React.lazy(() => import("./screens/UsersScreen"));

export const RootRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeScreen />} />
      <Route path="/logowanie" element={<LoginScreen />} />
      <Route path="/tworcy" element={<CreatorsScreen />} />
      <Route path="/uzytkownicy" element={<UsersScreen />} />
    </Routes>
  );
};

export default RootRouter;
