import { Suspense } from "react";
import Loading from "./shared/components/Loading";
import RootRouter from "./rootRouter";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <RootRouter />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
