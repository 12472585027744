import { StyledLoading } from "./styles";

export default function Loading() {
  return <StyledLoading>
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </StyledLoading>;
}